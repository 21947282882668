import { usePageContent } from "@appiodev/xcore-client/xcore-ui";
import { Container } from "@xcorejs/ui";
import RobeHead from "components/RobeHead";
import UserLoginComponent from "components/UserLoginComponent";
import { withAuth } from "components/withAuth";
import React from "react";
import { getRobeProps, RobePage } from "xcore";
import { UserLoginPage } from "xcore/types";

export const getStaticProps = getRobeProps(() => ({ pageContent: "userLoginPage" }));

const LoginPage: RobePage = () => {
  const [{ values }, { stringify }] = usePageContent<UserLoginPage>();

  return (
    <>
      <RobeHead title={stringify(values.userLoginHeading)} />

      <Container
        flexDirection="column"
        paddingTop={{ _: "3rem", sm: "5rem" }}
        paddingBottom={{ _: "6rem", sm: "10rem" }}
      >
        <UserLoginComponent />
      </Container>
    </>
  );
};

export default withAuth(false, LoginPage);
