import { useLink } from "@appiodev/xcore-client/xcore-ui";
import { Box } from "@xcorejs/ui";
import { ComponentType, FC, useEffect, useState } from "react";
import { RobePage, useLayout } from "xcore";

import { useUser } from "../utils/useUser";
import Layout from "./Layout";
import RobeLoader from "./RobeLoader";

export const withAuth = (shouldBeLoggedIn: boolean, C: ComponentType) => {
  const WithAuth: RobePage = () => {
    const { login, accountUpdate } = useLayout();
    const { push } = useLink();
    const [state, setState] = useState(false);
    const { user } = useUser();

    useEffect(() => {
      if (user === undefined) return;
      const shouldRedirect = shouldBeLoggedIn ? user === null : user !== null;

      shouldRedirect ? push(shouldBeLoggedIn ? login : accountUpdate) : setState(true);
    }, [accountUpdate, login, push, user]);

    return !state ? <LoadingPage /> : <C />;
  };

  WithAuth.Layout = Layout;

  return WithAuth;
};

export const LoadingPage: RobePage = () => {
  return (
    <Box display="flex" justifyContent="center" my="7rem">
      <RobeLoader />
    </Box>
  );
};
